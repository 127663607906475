import * as React from 'react';
import styled from 'styled-components';
import { useProgressiveImage } from '../../../../utils/hooks/useProgressiveImage';
import VisibilitySensor from 'react-visibility-sensor';

type BrokerImageSize = 'small' | 'medium' | 'large';

interface IBrokerImage {
  className?: string;
  url: string;
  size: BrokerImageSize;
}

export const Image: React.FC<IBrokerImage> = React.memo(
  ({ className, url, size = 'large' }) => {
    const [visible, setVisible] = React.useState(false);

    const { loaded } = useProgressiveImage({
      url: url,
      visible: visible
    });

    let classes = `${className ? className : ''} ${size ? size : ''}`;

    const visibilityChanged = (isVisible: boolean) => {
      if (!visible && isVisible === true) {
        setVisible(true);
      }
    };

    return (
      <VisibilitySensor
        active={!visible}
        onChange={visibilityChanged}
        partialVisibility={true}
        offset={{ bottom: 0 }}
      >
        <StyledBrokerImageWrapper className={classes}>
          <StyledBrokerImage image={url} className={loaded ? 'loaded' : ''} />
        </StyledBrokerImageWrapper>
      </VisibilitySensor>
    );
  },
  (prevProps, nextProps) => {
    if (prevProps.url === nextProps.url) {
      return true;
    }
    return false;
  }
);

interface IProps {
  loaded?: boolean;
  url?: string;
}

const StyledBrokerImage = styled.div<any>`
  transition: opacity 0.15s ease-in-out;
  width: 100%;
  height: 100%;
  background: url(${(props) => props.image}) no-repeat;
  background-size: cover;
  background-position: 50% 20%;
  opacity: 0;
  box-shadow: 0 0 6px 6px rgba(0, 0, 0, 0.7) inset;

  &.loaded {
    opacity: 1;
  }
`;

const StyledBrokerImageWrapper = styled.div`
  position: relative;
  background-clip: padding-box;
  background: rgb(40, 40, 40);
  overflow: hidden;

  &.large {
    width: 150px;
    height: 150px;
  }
  &.medium {
    width: 105px;
    height: 105px;
  }
  &.small {
    width: 55px;
    height: 55px;
  }

  img {
    position: absolute;
    width: 1;
    height: 1;
    z-index: -1;
    visibility: hidden;
  }
`;
